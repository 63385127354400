import * as Backbone from 'backbone';
import * as _ from 'underscore';
import { VIEWPORT_EVENTS } from '../../../constants/Constants';
import './Popup.less';

const Selectors = {
    el: '.Popup',
    close: '.Popup-close',
    overlay: '.Popup-overlay'
};

const BindFunctions = [
    'onClose',
    'checkAndShow',
    'handleEscKey'
];

export default class Popup extends Backbone.View {
    events() {
        return {
            [`${VIEWPORT_EVENTS.click} ${Selectors.close}`]: this.onClose
        };
    }

    constructor() {
        super({ el: Selectors.el });
        _.bindAll(this, BindFunctions);

        this.initialize();
    }

    initialize() {
        $(document).on('keydown', this.handleEscKey);

        const lastShown = localStorage.getItem('popupLastShown');
        const today = new Date().toDateString();

        if (lastShown !== today) {
            setTimeout(this.checkAndShow, 10000);
        }
    }

    handleEscKey(e) {
        if (e.keyCode === 27 && this.$el.hasClass('show')) {
            this.onClose();
        }
    }

    checkAndShow() {
        const today = new Date().toDateString();

        // this.$el.addClass('show');

        $('body').addClass('disableScroll');

        localStorage.setItem('popupLastShown', today);
    }

    onClose() {
        this.$el.removeClass('show');

        $('body').removeClass('disableScroll');
    }

    // Очищаем обработчики при удалении представления
    remove() {
        $(document).off('keydown', this.handleEscKey);
        Backbone.View.prototype.remove.call(this);
    }
}
